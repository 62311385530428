"use strict";
// window.$ = window.jQuery = require('jquery');
let ejs = require('ejs');
let MD5 = require("crypto-js/md5");
let order = 'prepend'; // or 'append'

const FLASH_SUCCESS = 'success';
const FLASH_INFO = 'info';
const FLASH_WARNING = 'warning';
const FLASH_DANGER = 'danger';

let selector = "#alerts-container";
let tpl = `
 <% if (message) { %>
    <div class="alert-dismissible alert alert-<%= type %> animated bounceInRight">
        <button type="button" class="close" data-dismiss="alert" aria-label="Fermer">
            <span aria-hidden="true">&times;</span>
        </button>

        <%= message %> 
    </div>
<% } %>
`;

export default {
    success: function (message) {
        _alert(message, FLASH_SUCCESS);
    },
    info: function (message) {
        _alert(message, FLASH_INFO);
    },
    warning: function (message) {
        _alert(message, FLASH_WARNING);
    },
    danger: function (message) {
        _alert(message, FLASH_DANGER);
    }
};

let alertOnWindow = function() {
    $(document).ready(function () {
        if (window.alerts) {
            window.alerts.forEach(function(alert) {
                console.log(alert);
                _alert(alert.value, alert.type);
            });
        }
    });
};

export { alertOnWindow };

let _alert = function (message, type = 'info') {
    let html = ejs.render(tpl, {message: message, type: type});
    let prev = $(selector).find('.alert');
    let alert = $(html).attr('data-hash', MD5(html)).delay(6000).hide({
        duration: 'fast',
        complete: function () {
            $(this).remove();
        },
    });

    prev.each(function () {
        if ($(this).attr('data-hash') === alert.attr('data-hash')) {
            $(this).remove();
        }
    });

    $(selector)[order](alert);
};